<template>
	<v-form v-model="valid" ref="form">
		<v-toolbar flat dense v-if="!hideTitle">
			<v-toolbar-title>{{ title || imodel.Title}}</v-toolbar-title>
			<v-spacer v-if="editButtonsTop" />
			<v-progress-circular v-if="editButtonsTop && loading" indeterminate class="mr-2" color="primary" />
			<edit-buttons
				v-if="editButtonsTop"
				v-bind="editButtonOptions"
				@save="doSaveClose"
				@delete="onDelete"
				@close="doClose"
				:showDelete="(idInternal > 0 || params) && (editButtonOptions.showDelete !== false)"
				:disabled="loading"
			/>
		</v-toolbar>

		<v-divider v-if="!hideTitle" />

		<div v-if="$scopedSlots.default" :style="{maxHeight:maxContentHeight,overflowX:'auto'}">
			<!-- @slot Replace everything -->
			<slot v-bind="slotScope"></slot>
		</div>

		<v-container
			v-if="!$scopedSlots.default"
			:style="{maxHeight:maxContentHeight,overflowX:'auto'}"
			@keydown.enter.prevent.stop="doSaveClose"
		>
			<!-- @slot Before the first field -->
			<slot name="before-fields" v-bind="slotScope" />
			<div v-for="f in getEditFields" :key="f.name">
				<!-- @slot Replace field -->
				<slot
					:name="'field.'+f.name"
					v-bind="{...slotScope,disabled:readOnly||isConstrained(f.name)||isDisabled(f.name)}"
				>
					<edit-input
						:name="f.name"
						:data="row"
						:errors="err"
						:disabled="readOnly||isConstrained(f.name)||isDisabled(f.name)"
					></edit-input>
				</slot>
			</div>
			<!-- @slot After the last field -->
			<slot name="after-fields" v-bind="slotScope" />
		</v-container>

		<v-divider/>

		<v-toolbar flat v-if="editButtonsBottom">
			<edit-buttons
				class="flex-grow-1"
				v-bind="editButtonOptions"
				@save="doSaveClose"
				@delete="onDelete"
				@close="doClose"
				:showDelete="(idInternal > 0 || params) && (editButtonOptions.showDelete !== false)"
				:disabled="loading"
			>
				<template #right-inside>
					<v-progress-circular v-if="loading" indeterminate class="mr-2" color="primary" />
				</template>
			</edit-buttons>
		</v-toolbar>

		<ConfirmDlg ref="deleteConfirm" label-confirm="Изтрий" color-confirm="red darken-1" icon-confirm="mdi-delete">
			Моля потвърдете изтриването на записа
		</ConfirmDlg>

		<v-snackbar
			color="red"
			v-model="snackbar.active"
			:timeout="-1"
			:multiLine="false"
		>
			<div v-for="(t,i) in snackbar.text" :key="i">{{ t }}</div>
			<template v-slot:action="{ attrs }">
				<v-btn
					text
					v-bind="attrs"
					@click="snackbar.active = false"
				>
					Затвори
				</v-btn>
			</template>
		</v-snackbar>

		<!-- @slot After everything, incl. edit buttons -->
		<slot name="after" v-bind="slotScope" />

	</v-form>
</template>

<script>
import EditMixin from '@/ittijs/EditMixin';
import FormMixin from '@/ittijs/FormMixin';

export default {

	mixins: [
		EditMixin,
		FormMixin,
	],

}
</script>