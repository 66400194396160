<template>
	<base-input
		v-bind="$attrs"
		v-on="$listeners"
		dense outlined hide-details="auto"
		v-if="calcInputType"
		:label="label"
		:value="value"
		@input="$emit('input', $event)"
		:type="calcInputType"
		:options="valOptions"
		clearable
	/>
</template>

<script>
import IModelMixin from "@/ittijs/IModelMixin";
import BaseInput from "@/ittijs/Inputs/BaseInput";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import {debounceAsync} from "@/ittijs/utils";

export default {
	name: "FilterValue",
	mixins: [IModelMixin, SelectedUtilityMixin],
	components: {BaseInput},
	props: {
		label: {
			type: String,
			default: "Стойност",
		},
		value: {},
		rule: Object,
		inputType: String,
	},
	data(){
		return {
			cascadeOptions: null,
			reloadCascadeDebounced: debounceAsync(this.reloadCascade, 100),
		}
	},
	computed: {
		fieldObj(){
			return this.rule && this.rule.field && this.imodel.fields[ this.rule.field ] || null;
		},
		searchOps(){
			return this.fieldObj && this.fieldObj.searchOps || null;
		},
		// return the operation def, converting the string label to object with key "label"
		selectedOp() {
			if (!(this.searchOps && this.rule && this.rule.op)) return null;
			const op = this.searchOps[ this.rule.op ];
			if (!op) return null;
			if (typeof op === 'string') {
				return {
					label: op,
				};
			}
			return op;
		},
		calcInputType() {
			if (this.inputType) return this.inputType;
			if (this.selectedOp && 'input' in this.selectedOp) {
				return this.selectedOp.input || null;
			}
			return this.fieldObj && this.fieldObj.inputType || "text";
		},
		valOptions(){
			return (this.selectedOp && this.selectedOp.options)
				|| (this.fieldObj && Array.isArray(this.fieldObj.dependsOn) && this.cascadeOptions)
				|| (this.fieldObj && this.fieldObj.options)
				|| [];
		},
	},
	watch: {
		fieldObj(){
			this.reloadCascadeDebounced();
		},
		selectedUtility(){
			this.reloadCascadeDebounced();
		},
		"selectedOp.input"(to){
			switch (to) {
				case 'select-multiple':
					this.$emit('input', []);
					break;
				default:
					this.$emit('input', null);
			}
		},
	},
	methods: {
		async reloadCascade(){
			this.cascadeOptions = null;
			if (Array.isArray(this.fieldObj.dependsOn) && this.fieldObj.optionsMethod) {
				const params = (this.fieldObj.dependsOn.includes('utility_code') ? {utility_code: this.selectedUtility} : {});
				this.cascadeOptions = await this.imodel.fetch(this.fieldObj.optionsMethod, params);
			}
		},
	},
}
</script>